import React, { useCallback, useState } from 'react';

import Icon from './Icon';

import './styles/aggregator.css';

export const ListAggregator = ({ onChange }) => {
    const [value, setValue] = useState('');

    const handleChange = useCallback(
        (e) => {
            e.preventDefault();
            onChange([value]);
            setValue('');
        },
        [value]
    );

    return (
        <form className="aggregator-block" onSubmit={handleChange}>
            <input type="text" className="aggregator-input" placeholder="Nouvel élément" value={value} onChange={({ target: { value } }) => setValue(value)} />
            <Icon name="plus" color="#808080" onClick={handleChange} />
        </form>
    );
};
