import { Fragment, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from '../../hooks/useForm';

import { Cards, Item, Lists, Notes, Products } from '../../models';

import { Modal, Icon } from '../../components';
import { ListAggregator } from '../../components/ListAggregator';

import css from '../../components/styles/modal.module.css';

export const ProductModal = ({ data, onClose, sync }) => {
    const [form, setForm] = useForm({ ...data });

    const handleSubmit = useCallback(() => {
        const product = new Products({ ...form });
        product
            .save()
            .then(() => {
                toast.success(`Produit ${data.id ? 'édité' : 'créé'} avec succès!`);
                sync();
                onClose();
            })
            .catch(() => {
                toast.error('Une erreur est survenue');
            });
    }, [form, sync, onClose]);

    return (
        <Modal label={data.id || 'Nouveau Produit'} handleSubmit={handleSubmit} onClose={onClose}>
            <div className={css['modal-input']}>
                <span>{'Nom'}</span>
                <input type="text" value={form['name']} onChange={({ target: { value } }) => setForm('name', value)} />
            </div>
            <div className={css['modal-input']}>
                <span>{'Quantité'}</span>
                <input type="text" value={form['quantity']} onChange={({ target: { value } }) => setForm('quantity', value)} />
            </div>
            <div className={css['modal-input']}>
                <span>{'Limite'}</span>
                <input type="text" value={form['limit']} onChange={({ target: { value } }) => setForm('limit', value)} />
            </div>
        </Modal>
    );
};

export const NoteModal = ({ data, onClose, sync }) => {
    const [form, setForm] = useForm({ ...data });

    const handleSubmit = useCallback(async () => {
        const product = new Notes({ ...form });
        product
            .save()
            .then(() => {
                toast.success(`Note ${data.id ? 'éditée' : 'créée'} avec succès!`);
                sync();
                onClose();
            })
            .catch(() => {
                toast.error('Une erreur est survenue');
            });
    }, [form, sync, onClose]);

    return (
        <Modal label={data.id || 'Nouvelle note'} handleSubmit={handleSubmit} onClose={onClose}>
            <div className={css['modal-input']}>
                <span>{'Nom'}</span>
                <input type="text" value={form['label']} onChange={({ target: { value } }) => setForm('label', value)} />
            </div>
            <div className={css['modal-input']}>
                <span>{'Texte'}</span>
                <textarea value={form['text']} onChange={({ target: { value } }) => setForm('text', value)} />
            </div>
        </Modal>
    );
};

export const ListModal = ({ data, onClose, sync }) => {
    const [form, setForm] = useForm({ ...data });

    const handleSubmit = useCallback(
        async (synch = true) => {
            let list = new Lists({ ...form });
            list.items = Array.from(new Set(form.items.map((it) => it.id)));
            list.save().then(() => {
                if (synch) {
                    toast.success(`Liste ${data.id ? 'éditée' : 'créée'} avec succès!`);
                    sync();
                    onClose();
                }
            });
        },
        [form, form.items, sync, onClose]
    );

    const handleSave = useCallback(
        async (items) => {
            await items.map((it) => {
                const item = new Item({ label: it });
                item.save().then((response) => {
                    setForm('items', Array.from(new Set([...form.items, response])));
                });
            });
            handleSubmit(false);
        },
        [form, handleSubmit]
    );

    return (
        <Modal label={data.id || 'Nouvelle liste'} handleSubmit={handleSubmit} onClose={onClose}>
            <div className={css['modal-input']}>
                <span>{'Nom'}</span>
                <input type="text" value={form['label']} onChange={({ target: { value } }) => setForm('label', value)} />
            </div>
            <ListAggregator onChange={handleSave} />
            <div className={css['modal-list-inputs']}>
                {form.items?.map((it, index) => (
                    <Fragment key={it.id}>
                        <ListItem item={it} index={index} form={form} setForm={setForm} />
                    </Fragment>
                ))}
            </div>
        </Modal>
    );
};

const ListItem = ({ item, index, form, setForm }) => {
    const [label, setLabel] = useState(item.label);

    const handleDelete = useCallback(() => {
        new Item({ ...item }).delete().then(() => {
            const items = [...form.items.filter((it) => it.id !== item.id)];
            setForm('items', items);
            let list = new Lists({ ...form });
            list.items = Array.from(new Set(items.map((it) => it.id)));
            list.save();
        });
    }, [form]);

    const handleChange = useCallback(
        (name, value) => {
            if (item[name] !== value) {
                let it = { ...item, [name]: value };
                new Item({ ...it }).save().then(() => {
                    let items = [...form.items];
                    items[index] = it;
                    setForm('items', items);
                });
            }
        },
        [form, item]
    );

    return (
        <div className={css['list-input']} aria-disabled={item.checked}>
            <div className={css['list-input-text']}>
                <input type="checkbox" checked={item.checked} onChange={({ target: { checked } }) => handleChange('checked', checked)} />
                <input
                    className={css['list-input-text-label']}
                    type="text"
                    value={label}
                    onChange={({ target: { value } }) => setLabel(value)}
                    onBlur={() => handleChange('label', label)}
                />
            </div>
            <Icon name="delete" color="gray" onClick={handleDelete} />
        </div>
    );
};

export const CardModal = ({ data, onClose, sync }) => {
    const [form, setForm] = useForm({ ...data });

    const handleSubmit = useCallback(async () => {
        const product = new Cards({ ...form });
        product
            .save()
            .then(() => {
                toast.success(`Carte ${data.uuid ? 'éditée' : 'créée'} avec succès!`);
                sync();
                onClose();
            })
            .catch(() => {
                toast.error('Une erreur est survenue');
            });
    }, [form, sync, onClose]);

    return (
        <Modal label={data.tag || 'Nouvelle carte'} handleSubmit={handleSubmit} onClose={onClose}>
            {data.uuid && data.codes?.length > 0 && (
                <div className={css['modal-qr-code-block']}>
                    <img src={data.codes[0]?.qr_code.split('?X')[0]} alt="qr-code" />
                </div>
            )}
            <div className={css['modal-input']}>
                <span>{'Nom'}</span>
                <input type="text" value={form['label']} onChange={({ target: { value } }) => setForm('label', value)} />
            </div>
            <div className={css['modal-input']}>
                <span>{'Tag'}</span>
                <input type="text" value={form['tag']} onChange={({ target: { value } }) => setForm('tag', value)} />
            </div>
        </Modal>
    );
};
