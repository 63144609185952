import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import HeaderButton from './HeaderButton';
import Icon from './Icon';

const Header = ({ title = '', onAdd = null }) => {
    const navigate = useNavigate();

    const rootUrl = useMemo(() => {
        return window.location.pathname === '/';
    }, [window]);

    const handleLogout = useCallback(() => {
        localStorage.removeItem('authToken');
        navigate('/login');
    }, [navigate]);

    return (
        <div className={'header-container'}>
            <span>{title}</span>
            <Icon
                className={'header-backlink'}
                name={rootUrl ? 'admin' : 'home'}
                color="#0076b8"
                onClick={() => {
                    if (!rootUrl) {
                        navigate('/');
                    } else {
                        window.location.assign(`${window.config.api.url}/admin`);
                    }
                }}
            />
            {onAdd && <HeaderButton text="Ajouter" mode="add" color="#0076b8" onClick={onAdd} />}
            <HeaderButton text="Déconnexion" mode="delete" color="#b84040" onClick={handleLogout} />
        </div>
    );
};

export default Header;
