import axios from 'axios';

export default class Fetcher {
    get url() {
        if (!this.id) {
            return `${window.config.api.url}/${this.path}`;
        }
        return `${window.config.api.url}/${this.path}/${this.id}`;
    }

    async create() {
        await axios
            .post(
                this.url,
                {
                    ...this
                },
                {
                    withCredentials: true,
                    headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
                }
            )
            .then((response) => {
                return response.data;
            });
    }

    async update() {
        await axios.patch(
            this.url,
            {
                ...this
            },
            {
                withCredentials: true,
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
            }
        );
    }

    async save() {
        if (!this.id) {
            return this.create();
        } else {
            return this.update();
        }
    }

    async delete() {
        await axios({
            method: 'delete',
            url: this.url,
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });
    }
}
