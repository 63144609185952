import { useNavigate } from 'react-router-dom';

import { useFetch } from '../../hooks/useFetch';
import { App } from '../../models';

import { Header, Loader, Icon } from '../../components';

import css from './apps.module.css';
import { useMemo } from 'react';

export const Apps = () => {
    const [apps, loadingApps] = useFetch({ method: 'GET', model: App });

    const appsList = useMemo(() => {
        if (!apps || loadingApps) {
            return [];
        }
        return [...apps, new App({ icon: 'cart', name: 'lists', label: 'Courses', url: '/memos/lists' })];
    }, [apps, loadingApps]);

    return (
        <>
            <Header title="Home" />
            <div className={css['apps-container']}>
                <Loader loading={loadingApps}>
                    {appsList?.map((app, index) => (
                        <AppItem key={index} item={app} />
                    ))}
                </Loader>
            </div>
        </>
    );
};

const AppItem = ({ item }) => {
    const navigate = useNavigate();
    return (
        <div className={css['app-card']} onClick={() => navigate(item.url)} disabled={!item.url}>
            <span className={css['app-card-label']}>{item.label} </span>
            <Icon name={item.icon} />
        </div>
    );
};
