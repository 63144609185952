import { Fetcher } from '.';
import axios from 'axios';

export default class Cards extends Fetcher {
    constructor(options = {}) {
        super();
        this.path = 'nfc/cards';
        for (const [key, value] of Object.entries(options)) {
            this[key] = value;
        }
    }

    static listUrl(id) {
        if (!id) {
            return `nfc/cards`;
        }
        return `nfc/cards/${id}`;
    }

    static async activate({ id, value }) {
        await axios({
            method: 'post',
            url: `${window.config.api.url}/nfc/cards/${id}/activate`,
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
            data: { value }
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    }
}
